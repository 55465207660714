body {
  background: linear-gradient(#e66465, #9198e5);
  background-repeat: no-repeat;
  min-height: 100vh;
  --webkit-background-size: cover;
}

.HomeCard,
.SummaryBox,
.LoadingCard {
  text-align: center;
  display: flex;
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: center;
}

.blinking {
  animation: mymove 1s infinite alternate;
  color: #3e48bf;
}

@keyframes mymove {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

Button:hover {
  background-color: rgb(71, 213, 161) !important;
  color: aliceblue !important;
}

.footer {
  width: 100%;
  /* position: fixed; */
  bottom: auto;
  opacity: 50%;
}

.typed {
  font-family: "Montserrat", sans-serif;
  margin: 0 auto;
  overflow: visible;
  white-space: normal;
}

/* Credit to Hakkam Abdullah for the below */

.title-text {
  background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
  background-size: cover;
  color: transparent;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  font-size: 75px;
  margin: 10px 0;
}

/* Credit to @alvarotrigo for loading bar css. */

svg {
  width: 114px;
  height: 114px;
  margin: 1em;
}

.bg-loading {
  fill: none;
  stroke-width: 10px;
  stroke: #38175d;
}

.meter-3 {
  stroke: rgb(130, 201, 212);
  stroke-dasharray: 360;
  stroke-dashoffset: 0;
  animation: progress-3 16s ease-in-out;
  fill: none;
  stroke-width: 7px;
  stroke-linecap: square;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

@keyframes progress-3 {
  from {
    stroke-dashoffset: 360;
  }

  to {
    stroke-dashoffset: 60;
  }
}
